import { useState } from "react";

type PageLoadingProps = {
    isLoading: boolean;
};

export const PageLoading: React.FC<PageLoadingProps> = ({
    isLoading
}) => {

    return (
        <>
            {
                isLoading &&
                <div className=" absolute top-0 left-0 h-full w-full z-50 flex justify-center items-center bg-slate-800 bg-opacity-50">
                    <span className="text-3xl font-bold text-white">
                        Loading...
                    </span>
                </div>
            }
        </>
    );
}