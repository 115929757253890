import { useState } from "react";

type UseSelectBoxProps = {
    initValue: string;
};

export const useSelectBox = ({ initValue }: UseSelectBoxProps) => {

    const [selectedValue, setSelectedValue] = useState<string>(initValue);

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(e.target.value);
    }

    return {
        selectedValue,
        onChange
    }
}