const URL = {
    PAGE: {
        dashboard: {
            index: '/'
        },
        client: {
            index: '/client',
            register: '/client/register'
        },
        project: {
            index: '/project'
        },
        assign: {
            index: '/assign'
        }
    },
    API: {
        auth: {
            check: process.env.REACT_APP_API_URL_BASE_GO + '/api/v1/authorization/check'
        },
        user: {
            show: process.env.REACT_APP_API_URL_BASE_GO + '/api/v1/users'
        },
        client: {
            index: process.env.REACT_APP_API_URL_BASE_GO + '/api/v1/clients',
            create: process.env.REACT_APP_API_URL_BASE_GO + '/api/v1/clients'
        },
        project: {
            create: process.env.REACT_APP_API_URL_BASE_GO + '/api/v1/projects'
        }
    }
}

export const Config = {
    URL: URL
}
