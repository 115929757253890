import { LaboroButton } from "@/components/button/LaboroButton";
import { GuestLayout } from "@/components/layout/GuestLayout";
import { signInWithRedirect } from "aws-amplify/auth";

const Top = () => {
    return (
        <GuestLayout>
            <div className="relative h-full flex flex-col justify-center items-center">
                <div className="text-3xl font-bold my-24">
                    Welcome to Sigmoid
                </div>
                <LaboroButton onClick={() => signInWithRedirect({ provider: "Google" })}>
                    Google認証
                </LaboroButton>
            </div>
        </GuestLayout >
    )
}

export default Top;