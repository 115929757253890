import React, { useMemo } from 'react';

type LaboroButtonProps = {
    children: React.ReactNode;
    onClick?: () => void;
    color?: "main" | "gray" | "red";
};

export const LaboroButton: React.FC<LaboroButtonProps> = ({
    children,
    onClick = () => { },
    color
}) => {

    const color_class = useMemo(() => {
        switch (color) {
            case "gray":
                return "bg-gray-400 hover:bg-gray-500 text-white";
            case "red":
                return "bg-rose-500 hover:bg-rose-600 text-white";
            default:
                return "bg-gradient-to-r from-laboro-blue to-laboro-green hover:from-laboro-dark-blue hover:to-laboro-dark-green text-white";
        }
    }, []);

    return (
        <button
            type="button"
            className={"px-6 py-2 rounded-md shadow-md font-bold " + color_class}
            onClick={onClick}
        >
            {children}
        </button>
    );
}