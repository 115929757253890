import { LaboroButton } from "@/components/button/LaboroButton";
import { LaboroTextBox } from "@/components/input/LaboroTextBox";
import { LaboroLabel } from "@/components/label/LaboroLabel";
import { AdminLayout } from "@/components/layout/AdminLayout";
import { LaboroSelectBox } from "@/components/select/LaboroSelectBox";
import { LaboroLinkButton } from "@/components/button/LaboroLinkButton";
import { LaboroForm } from "@/components/form/LaboroForm";
import { ValidationErrors } from "@/components/validation/ValidationErrors";
import { useProjectCreate } from "./hooks/useProjectCreate";

const ProjectCreate = () => {

    const projectCreateHook = useProjectCreate();

    return (
        <AdminLayout title="プロジェクト追加">
            <LaboroForm _class=" w-128">
                <div className="">
                    <LaboroLabel targetId="client">クライアント名</LaboroLabel>
                    <LaboroSelectBox
                        name="client"
                        id="client"
                        value={projectCreateHook.clientSelectValue}
                        onChange={projectCreateHook.clientSelectOnChange}
                    >
                        return (<option value="">選択してください</option>);
                        {
                            projectCreateHook.clientList.map((client) => {
                                return (
                                    <option key={client.value} value={client.value}>{client.name}</option>
                                );
                            })
                        }
                    </LaboroSelectBox>
                </div>
                <div className="mt-4">
                    <LaboroLabel targetId="project_name">プロジェクト名</LaboroLabel>
                    <LaboroTextBox
                        id="project_name"
                        name="project_name"
                        placeholder="プロジェクト名を入力してください"
                        value={projectCreateHook.projectValue}
                        onChange={projectCreateHook.projectOnChange}
                    />
                    <ValidationErrors id={"projectName"} validationErrors={projectCreateHook.validationErrors} />
                </div>
                <div className="mt-4 flex justify-between">
                    <LaboroLinkButton color="gray" href="/">戻る</LaboroLinkButton>
                    <LaboroButton onClick={projectCreateHook.onSave}>登録</LaboroButton>
                </div>
            </LaboroForm>
        </AdminLayout >
    )
}

export default ProjectCreate;