import { Config } from "@/config";
import { sigmoidAxios } from "@/lib/sigmoidAxios";
import { UserAuth } from "@/routes/Router";
import { SigmoidClient } from "@/types/SigmoidClient";
import { useContext, useEffect, useState } from 'react';


export const useClientIndex = () => {

    const auth = useContext(UserAuth);
    const [clientList, setClientList] = useState<SigmoidClient[]>([]);

    useEffect(() => {
        console.log("初期処理");
        setClientList([]);
        getClients();
    }, []);

    const getClients = async () => {
        console.log("クライアント全件取得");
        sigmoidAxios.getWithToken(
            Config.URL.API.client.index,
            auth.token!
        ).then((response) => {
            console.log("response", response);
            onClientIndexSuccess(response.data);
        }).catch((error) => {
            console.log("error", error);
            onClientIndexFailure();
        });
    }

    const onClientIndexSuccess = (clients: SigmoidClient[]) => {
        setClientList(clients);
    }

    const onClientIndexFailure = () => {
        setClientList([
            // NOTE: クライアント一覧テーブル上にエラーメッセージを表示するためのダミーデータ
            { clientId: "-", clientName: "データ取得時にエラーが発生しました。" },
        ]);
    }

    return {
        clientList,
    }
}
