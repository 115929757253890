type LaboroFormProps = {
    children: React.ReactNode;
    _class?: string;
};

export const LaboroForm: React.FC<LaboroFormProps> = ({
    children,
    _class = ""
}) => {

    return (
        <div className={"bg-slate-50 px-4 py-4 rounded-lg shadow-lg " + _class}>
            {children}
        </div>
    );
}