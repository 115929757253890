import { AdminLayout } from "@/components/layout/AdminLayout";
import { useClientIndex } from "./hooks/useClientIndex";
import { LaboroLinkButton } from "@/components/button/LaboroLinkButton";
import { Config } from "@/config";

const ClientIndex = () => {

    const clientIndexHook = useClientIndex();

    return (
        <AdminLayout title="クライアント一覧">
            <div className="text-right mb-5">
                <LaboroLinkButton href={Config.URL.PAGE.client.register}>クライアント登録</LaboroLinkButton>
            </div>
            <div className="">
                <table className="sigmoid">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="w-14">ID</th>
                            <th>クライアント名</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {
                            clientIndexHook.clientList.map((client) => {
                                return (
                                    <tr key={client.clientId}>
                                        <td>{client.clientId}</td>
                                        <td>{client.clientName}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </AdminLayout >
    )
}

export default ClientIndex;
