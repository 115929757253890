import { useState } from "react";

type UseTextBoxProps = {
    initValue?: string;
};

export const useTextBox = ({
    initValue = ""
}: UseTextBoxProps) => {

    const [value, setValue] = useState<string>(initValue);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }

    return {
        value,
        onChange
    }
}