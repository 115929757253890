import { Config } from "@/config";
import { UserAuth } from "@/routes/Router";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const Header = () => {

    const auth = useContext(UserAuth);
    const [isSettingVisible, setSettingVisible] = useState(false);

    const hideSetting = () => setSettingVisible(false);
    const showSetting = () => setSettingVisible(true);


    return (
        <header className=" relative z-10 bg-gradient-to-r from-laboro-blue to-laboro-green h-14 shadow-xl">
            <div className="flex justify-between items-center h-full px-4">
                <Link to={Config.URL.PAGE.dashboard.index}>
                    <div className=" text-2xl font-bold text-white">
                        Sigmoid
                    </div>
                </Link>
                <div
                    className=" relative h-full flex items-center"
                    onMouseLeave={hideSetting}
                >
                    <div
                        className="px-2 py-2 hover:bg-lime-600 hover:bg-opacity-30 text-lg rounded-lg text-white font-bold cursor-pointer"
                        onMouseOver={showSetting}
                    >
                        {auth.sigmoidUser?.email}
                    </div>
                    {
                        isSettingVisible &&
                        <div className="absolute top-14 w-full px-2 py-2 z-10 bg-white rounded text-slate-600">
                            <div className="px-1 py-1">
                                <button onClick={auth.signOut} className="">
                                    ログアウト
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </header>
    );
}