import { ValidationErrorsType } from "@/types/ValidationError";
import { useMemo } from "react";

type ValidationErrorsProps = {
    validationErrors?: ValidationErrorsType;
    id: string;
};

export const ValidationErrors: React.FC<ValidationErrorsProps> = ({
    validationErrors = null,
    id,
}) => {

    const contetnt = useMemo(() => {
        if (validationErrors == null || typeof validationErrors !== 'object' || validationErrors[id] == undefined) {
            return null;
        }
        return (
            <div className='mt-1 text-rose-500'>
                <ul>
                    {
                        validationErrors[id].map((error: string, index: number) => {
                            return (
                                <li key={index} className='list-disc ml-5'>{error}</li>
                            );
                        })
                    }
                </ul>
            </div>
        );
    }, [validationErrors]);

    return (
        <>{contetnt}</>
    );
}