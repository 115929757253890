import { useState } from "react";

import { ValidationErrorsType } from "@/types/ValidationError";


export const useValidationErrors = () => {

    const [errors, setErrors] = useState<ValidationErrorsType>();

    const clearErrors = () => {
        setErrors(undefined);
    }

    return {
        errors,
        setErrors,
        clearErrors
    }
}
