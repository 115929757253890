import { PageLoading } from "@/components/loading/PageLoading";
import { useMemo, useState } from "react";

export const usePageLoading = () => {

    const [isLoading, setIsLoading] = useState(false);
    const Loader = useMemo(() => {
        return <PageLoading isLoading={isLoading} />
    }, [isLoading]);

    const show = () => {
        setIsLoading(true);
    }

    const close = () => {
        setIsLoading(false);
    }

    return {
        Loader,
        show,
        close
    }
}