import { AdminLayout } from '@/components/layout/AdminLayout';
import { useDashboard } from './hooks/useDashboard';


const Dashboard = () => {

    const dashboardStatus = useDashboard();

    return (
        <AdminLayout>
            {dashboardStatus.loading.Loader}
            <div className="Top">
                <div className="text-3xl font-bold text-center my-10">
                    Hello, {dashboardStatus.userName}!!
                </div>
                <div>
                    your token is...
                </div>
                <textarea name="" id="" cols={120} rows={20} value={dashboardStatus.auth.token} onChange={() => { }}></textarea>
            </div>
        </AdminLayout >
    )
}

export default Dashboard;