import React from 'react';

type LaboroTextBoxProps = {
    id?: string;
    name?: string;
    placeholder?: string;
    value?: string;
    _class?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const LaboroTextBox: React.FC<LaboroTextBoxProps> = ({
    id,
    name,
    placeholder,
    value = "",
    _class = "",
    onChange = () => { }
}) => {
    return (
        <input
            type="text"
            id={id}
            name={name}
            className={"w-full px-2 py-1 border rounded-md " + _class}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
        />
    );
}