import { AuthLoading } from "@/components/loading/AuthLoading";
import ClientCreate from "@/features/client/ClientCreate";
import ClientIndex from "@/features/client/ClientIndex";
import Dashboard from "@/features/dashboard/Dashboard";
import ProjectCreate from "@/features/project/ProjectCreate";
import Top from "@/features/top/Top";
import { UseAuthType, useAuth } from "@/hooks/useAuth";
import { createContext } from "react";
import { Route, Routes } from "react-router-dom";

export const UserAuth = createContext<UseAuthType>({} as UseAuthType);

export const Router = () => {

    const auth = useAuth();

    const adminRoutes = (
        <>
            < Route path="/client" element={<ClientIndex />} />
            < Route path="/client/register" element={<ClientCreate />} />
            < Route path="/project" element={<ProjectCreate />} />
            < Route path="*" element={<Dashboard />} />
        </>
    )

    const guestRoutes = (
        <Route path="*" element={<Top />} />
    )

    const swithRoute = () => {
        switch (auth.sigmoidAuthStatus) {
            case 'authenticated':
                return adminRoutes
            default:
                return guestRoutes
        }
    }

    return (
        <UserAuth.Provider value={auth}>
            {
                auth.sigmoidAuthStatus === 'loading' ?
                    <AuthLoading />
                    :
                    <Routes>
                        {swithRoute()}
                    </Routes >
            }
        </UserAuth.Provider>
    );
}
