import React from 'react';

type LaboroLabelProps = {
    children: React.ReactNode;
    targetId?: string;
    _class?: string;
};

export const LaboroLabel: React.FC<LaboroLabelProps> = ({
    children,
    targetId,
    _class = ""
}) => {
    return (
        <label
            htmlFor={targetId}
            className={"block mb-1 text-md font-semibold text-slate-600 " + _class}
        >
            {children}
        </label>
    );
}