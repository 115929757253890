import { Header } from "../header/Header";
import { Sidebar } from "../sidebar/Sidebar";

type AdminLayoutProps = {
    children: React.ReactNode;
    title?: string;
};

export const AdminLayout = ({ children, title }: AdminLayoutProps) => {
    return (
        <>
            <div className=" relative flex flex-col min-h-screen">
                <Header />
                <div className=" relative flex flex-1">
                    <Sidebar />
                    <div className=" relative flex-1 bg-slate-200 px-6 pt-6">
                        {
                            title &&
                            <div className="flex items-center mb-6 text-3xl font-bold">
                                <span className=" inline-block w-1.5 h-8 mr-1 bg-black"></span>
                                <span>{title}</span>
                            </div>
                        }

                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}