import { LaboroButton } from "@/components/button/LaboroButton";
import { LaboroLinkButton } from "@/components/button/LaboroLinkButton";
import { LaboroForm } from "@/components/form/LaboroForm";
import { LaboroTextBox } from "@/components/input/LaboroTextBox";
import { LaboroLabel } from "@/components/label/LaboroLabel";
import { AdminLayout } from "@/components/layout/AdminLayout";
import { ValidationErrors } from "@/components/validation/ValidationErrors";
import { useClientCreate } from "./hooks/useClientCreate";
import { useClientIndex } from "./hooks/useClientIndex";
import { Config } from "@/config";

const ClientCreate = () => {

    const clientCreateHook = useClientCreate();
    const clientIndexHook = useClientIndex();

    return (
        <AdminLayout title="クライアント追加">
            {/* TODO: いずれはモーダル上から登録できるようにする */}
            <LaboroForm _class=" w-128">
                <div className="mt-4">
                    <LaboroLabel targetId="clientName">クライアント名</LaboroLabel>
                    <LaboroTextBox
                        id="clientName"
                        name="clientName"
                        placeholder="クライアント名を入力してください"
                        value={clientCreateHook.clientValue}
                        onChange={clientCreateHook.clientOnChange}
                    />
                    <ValidationErrors id={"clientName"} validationErrors={clientCreateHook.validaionErrors} />
                </div>
                <div className="mt-4 flex justify-between">
                    <LaboroLinkButton color="gray" href={Config.URL.PAGE.client.index}>戻る</LaboroLinkButton>
                    <LaboroButton onClick={clientCreateHook.onSave}>登録</LaboroButton>
                </div>
            </LaboroForm>
        </AdminLayout >
    )
}

export default ClientCreate;
