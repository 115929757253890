import { Config } from "@/config";
import { useSelectBox } from '@/hooks/useSelectBox';
import { useTextBox } from '@/hooks/useTextBox';
import { useValidationErrors } from '@/hooks/useValidationErrors';
import { useClientIndex } from "./../../client/hooks/useClientIndex";
import { sigmoidAxios } from '@/lib/sigmoidAxios';
import { UserAuth } from "@/routes/Router";
import { useContext, useEffect, useState } from 'react';

export type Client = {
    value: string;
    name: string;
};

export const useProjectCreate = () => {

    const auth = useContext(UserAuth);
    const validation = useValidationErrors();
    const clientIndexHook = useClientIndex();

    const clientSelect = useSelectBox({ initValue: "" })

    const projectTextBox = useTextBox({});

    const [clientList, setClientList] = useState<Client[]>([]);

    useEffect(() => {
        setClientList(
            clientIndexHook.clientList.map((client) => ({
                value: client.clientId,
                name: client.clientName
            }))
        );
    }, [clientIndexHook.clientList]);

    const onSave = () => {
        const clientId = Number(clientSelect.selectedValue);

        const client = clientList.find(c => c.value === clientSelect.selectedValue);
        const clientName = client ? client.name : "未選択";

        const projectData = { "clientId": clientId, "projectName": projectTextBox.value };

        // アラート画面の表示
        const confirmationMessage = `登録内容は以下でよろしいですか？\n\nクライアント名: ${clientName}\nプロジェクト名: ${projectTextBox.value}`;
        const confirmation = window.confirm(confirmationMessage);

        if (confirmation) {
            sigmoidAxios.postWithToken(
                Config.URL.API.project.create,
                auth.token!,
                projectData
            ).then((response) => {
                onProjectCreateSuccess();
            }).catch((error) => {
                onProjectCreateFailure(error.response.data.errors);
            });
        }
    }

    const onProjectCreateSuccess = () => {
        validation.setErrors({});
    }

    const onProjectCreateFailure = (
        errors: Array<string>
    ) => {
        validation.setErrors({
            "clientId": errors,
            "projectName": errors,
        });
    }

    return {
        clientSelectValue: clientSelect.selectedValue,
        clientSelectOnChange: clientSelect.onChange,
        projectValue: projectTextBox.value,
        projectOnChange: projectTextBox.onChange,
        clientList,
        onSave,
        validationErrors: validation.errors,
    }
}
