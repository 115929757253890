import { Config } from "@/config";
import { usePageLoading } from "@/hooks/usePageLoading";
import { sigmoidAxios } from "@/lib/sigmoidAxios";
import { UserAuth } from "@/routes/Router";
import { useState, useEffect, useContext } from "react";

export const useDashboard = () => {
    const auth = useContext(UserAuth);
    const loading = usePageLoading();

    const [userName, setUserName] = useState<string>('');

    useEffect(() => {
        loading.show();
        sigmoidAxios.getWithToken(
            `${Config.URL.API.user.show}/${auth.sigmoidUser?.email}`,
            auth.token!
        ).then((response) => {
            setUserName(response.data.name)
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            loading.close();
        });
    }, []);

    return {
        userName,
        auth,
        loading
    }
}