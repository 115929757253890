import axios from "axios";

const sigmoidAxiosBase = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
})

export class sigmoidAxios {
    static get(url: string, data: object = {}, headers: object = {}) {
        return sigmoidAxiosBase.get(url, {
            params: data,
            headers: headers
        });
    }
    static post(url: string, data: object = {}, headers: object = {}) {
        return sigmoidAxiosBase.post(url, data, {
            headers: headers
        });
    }

    static getWithToken(url: string, token: string, data: object = {}, headers: object = {}) {
        const headerWithToken = {
            ...headers,
            Authorization: `Bearer ${token}`
        }
        return sigmoidAxios.get(url, data, headerWithToken);
    }

    static postWithToken(url: string, token: string, data: object = {}, headers: object = {}) {
        const headerWithToken = {
            ...headers,
            Authorization: `Bearer ${token}`
        }
        return sigmoidAxios.post(url, data, headerWithToken);
    }
}