import { Config } from "@/config";
import { useSelectBox } from '@/hooks/useSelectBox';
import { useTextBox } from '@/hooks/useTextBox';
import { useValidationErrors } from '@/hooks/useValidationErrors';
import { sigmoidAxios } from "@/lib/sigmoidAxios";
import { UserAuth } from "@/routes/Router";
import { ValidationErrorsType } from "@/types/ValidationError";
import { fetchAuthSession } from "aws-amplify/auth";
import { useContext, useEffect, useState } from 'react';

export type Client = {
    value: string;
    name: string;
};

export const useClientCreate = () => {

    const auth = useContext(UserAuth);
    const validation = useValidationErrors();

    const clientSelect = useSelectBox({
        initValue: "A"
    });

    const clientTextBox = useTextBox({});

    const [clientList, setClientList] = useState<Client[]>([]);

    useEffect(() => {
        console.log("初期処理");
        setClientList([]);
        validation.setErrors({});
    }, []);

    const onSave = async () => {
        console.log("保存処理");
        let clientData = { "clientName": clientTextBox.value }
        console.log("clientSelect", clientSelect.selectedValue);
        console.log("clientName", clientData);
        sigmoidAxios.postWithToken(
            Config.URL.API.client.create,
            auth.token!,
            clientData
        ).then((response) => {
            console.log("response", response);
            onClientCreateSuccess();
        }).catch((error) => {
            console.log("error", error);
            onClientCreateFailure(error.response.data.errors);
        });
    }


    const onClientCreateSuccess = () => {
        console.log("クライアント作成成功");
        validation.setErrors({});
    }

    const onClientCreateFailure = (
        errors: Array<string>
    ) => {
        console.log("クライアント作成失敗");
        console.log("error", errors);
        validation.setErrors({
            "clientName": errors,
        });
    }

    return {
        clientSelectValue: clientSelect.selectedValue,
        clientSelectOnChange: clientSelect.onChange,
        clientValue: clientTextBox.value,
        clientOnChange: clientTextBox.onChange,
        clientList,
        onSave,
        validaionErrors: validation.errors,
    }
}
