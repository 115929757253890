import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Router } from './routes/Router';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOLS_ID!,
            userPoolClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOLS_CLIENT_ID!,
            loginWith: { // Optional
                oauth: {
                    domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN!,
                    scopes: [
                        'openid', 'email'
                    ],
                    redirectSignIn: [
                        process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGNIN_URL!.replace(/\/$/, '')
                    ],
                    redirectSignOut: [
                        process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGNOUT_URL!.replace(/\/$/, '')
                    ],
                    responseType: 'code',
                },
            }
        }
    }
});

export const App = () => {
    return (
        <Authenticator.Provider>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </Authenticator.Provider>
    );
}