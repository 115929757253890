import { Config } from "@/config";
import { Link, useLocation } from "react-router-dom";

export const Sidebar = () => {

    const location = useLocation();

    const navArray = [
        {
            name: "ダッシュボード",
            link: Config.URL.PAGE.dashboard.index
        },
        {
            name: "クライアント管理",
            link: Config.URL.PAGE.client.index
        },
        {
            name: "プロジェクト管理",
            link: Config.URL.PAGE.project.index
        },
        {
            name: "アサイン管理",
            link: Config.URL.PAGE.assign.index
        }
    ]

    const isActived = (link: string) => {
        return location.pathname === link;
    }

    return (
        <div className=" relative z-10 w-56 pt-8 bg-slate-100 shadow-lg">
            <ul>
                {
                    navArray.map((item, index) => {
                        return (
                            <li key={index}>
                                <Link
                                    to={item.link}
                                    className={
                                        "inline-block w-full h-full my-2 px-4 py-2 font-bold hover:bg-slate-300 hover:bg-opacity-50 cursor-pointer"
                                        + (isActived(item.link) ? " bg-slate-200" : "")
                                    }
                                >
                                    {item.name}
                                </Link>
                            </li>
                        );

                    })
                }
            </ul>
        </div>
    );
}
