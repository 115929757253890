import { Config } from "@/config";
import { sigmoidAxios } from "@/lib/sigmoidAxios";
import { SigmoidUser } from "@/types/SigmoidUser";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { JWT, fetchAuthSession } from "aws-amplify/auth";
import { useEffect, useState } from "react";

export type UseAuthType = {
    signOut: () => void;
    isSignedIn: () => boolean;
    jwt: JWT | undefined;
    token: string | undefined;
    sigmoidUser: SigmoidUser | undefined;
    sigmoidAuthStatus: SigmoidAuthStatus;
};

export type SigmoidAuthStatus = 'authenticated' | 'unauthenticated' | 'loading';


export const useAuth = () => {

    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const [sigmoidAuthStatus, setSigmoidAuthStatus] = useState<SigmoidAuthStatus>('unauthenticated');
    const [sigmoidUser, setSigmoidUser] = useState<SigmoidUser>();
    const [jwt, setJwt] = useState<JWT>();
    const [token, setToken] = useState<string>();

    useEffect(() => {
        if (authStatus === 'authenticated') {
            checkSigmoidAuth();
        }
        if (authStatus === 'configuring') {
            setSigmoidAuthStatus('loading');
        }
        if (authStatus === 'unauthenticated') {
            setSigmoidAuthStatus('unauthenticated');
        }
    }, [authStatus]);

    const checkSigmoidAuth = async () => {
        const session = await fetchAuthSession();
        const jwt = session.tokens?.idToken;
        if (jwt) {
            const jwtToken = jwt.toString();
            sigmoidAxios.postWithToken(
                Config.URL.API.auth.check,
                jwtToken
            ).then((response) => {
                onAuthenticationSuccess(jwt);
            }).catch((error) => {
                onAuthenticationFailure();
            });
        }
    }

    const onAuthenticationSuccess = (jwt: JWT) => {
        const jwtToken = jwt.toString();
        setJwt(jwt);
        setSigmoidUser({
            email: jwt.payload.email?.toString() || '',
        })
        setToken(jwtToken);
        setSigmoidAuthStatus('authenticated');
    }

    const onAuthenticationFailure = () => {
        setSigmoidAuthStatus('unauthenticated');
        signOut();
    }

    const isSignedIn = () => {
        return sigmoidAuthStatus === 'authenticated';
    }

    return { signOut, isSignedIn, jwt, token, sigmoidUser, sigmoidAuthStatus };
};