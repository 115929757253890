import React from 'react';

type LaboroSelectBoxProps = {
    children?: React.ReactNode;
    id?: string;
    name?: string;
    value?: string;
    _class?: string;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const LaboroSelectBox: React.FC<LaboroSelectBoxProps> = ({
    children,
    id,
    name,
    value = "",
    _class = "",
    onChange = () => { },
}) => {
    return (
        <select
            id={id}
            name={name}
            className={"w-full px-2 py-1 border rounded-md " + _class}
            value={value}
            onChange={onChange}
        >
            {children}
        </select>
    );
}