
export const GuestLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <div className="relative h-full min-h-screen flex flex-col">
                <header className=" bg-gradient-to-r from-laboro-blue to-laboro-green h-10 shadow-xl">
                </header>
                <div className="flex-1">
                    {children}
                </div>
                <div className="py-1 bg-slate-300 text-center text-sm text-slate-700">
                    © 2024 Laboro.AI. All Rights Reserved.
                </div>
            </div>
        </>
    );
}